exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-mix-post-js": () => import("./../../../src/templates/mix-post.js" /* webpackChunkName: "component---src-templates-mix-post-js" */),
  "component---src-templates-mixes-page-js": () => import("./../../../src/templates/mixes-page.js" /* webpackChunkName: "component---src-templates-mixes-page-js" */),
  "component---src-templates-playlists-page-js": () => import("./../../../src/templates/playlists-page.js" /* webpackChunkName: "component---src-templates-playlists-page-js" */),
  "component---src-templates-release-post-js": () => import("./../../../src/templates/release-post.js" /* webpackChunkName: "component---src-templates-release-post-js" */),
  "component---src-templates-releases-page-js": () => import("./../../../src/templates/releases-page.js" /* webpackChunkName: "component---src-templates-releases-page-js" */)
}

